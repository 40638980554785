export const state = () => ({
  maintenanceInfo: {
    is_active: false,
    message: ''
  }
})

export const mutations = {
  setMaintenanceInfo(state, info) {
    state.maintenanceInfo = info
  }
}

export const getters = {
  maintenanceInfo: state => state.maintenanceInfo,
  isMaintenanceMode: state => state.maintenanceInfo.is_active
} 