export default function ({ store, redirect, route, $axios }) {
  return $axios.$get(`${process.env.MAIN_API}/aec/maintenance`)
    .then(response => {
      if (response.status === 'success' && response.result) {
        const maintenanceInfo = response.result
        store.commit('maintenance/setMaintenanceInfo', maintenanceInfo)
        
        if (maintenanceInfo.is_active && route.path !== '/') {
          return redirect('/')
        }
      }
    })
    .catch(error => {
      console.error('Error checking maintenance status:', error.message)
      store.commit('maintenance/setMaintenanceInfo', {
        is_active: false,
        message: ''
      })
    })
}